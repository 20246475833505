import {getPlatforms, IonCol, IonImg} from '@ionic/react';
import { isPlatform } from '@ionic/react';
import { IonModal, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonList, IonInput } from '@ionic/react';
import logo from '../images/Wiki_Harries_Logo.png';
import background_box_icon from '../images/Harries_mobile_HG.png';
import background_desktop from '../webapp/images/Harries_mobile_HG-2.jpg';
import background_mobile from '../images/Harries_mobile_HG-2.jpg';
import openai_loader from '../images/openai_waiting.gif';
import ExploreContainer from '../components/ExploreContainer';
import Parser from 'html-react-parser';
import axios from 'axios';
import { useState, useEffect } from "react";
import './Tab4.css';
import { userLogin } from '../components/UserLogin';
import {Storage} from "@capacitor/storage";
import {getElement} from "ionicons/dist/types/stencil-public-runtime";

const Tab4: React.FC = () => {
    let settingsloaded = false;
    const [username, setUser] = useState<string>();
    const [password, setPasswd] = useState<string>();
    const [background, setbackground] = useState<string>();
    // const initialMaxLengthnumberState = {
    //     value: 256,
    // }
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [prompt, setPrompt] = useState<string>();
    // const [maxLengthnumber, setMaxLengthnumber] = useState(initialMaxLengthnumberState);
    const [items, setItems] = useState<any>([]);
    const [items_prompt, setItemsPrompt] = useState<any>([]);
    const [history_id, setHistoryID] = useState<any>('');
    const [history_items, setHistoryItems] = useState<any>([]);
    // const [items, setItems] = useState<prompt_items[]>([]);
    const getAxiosDataopenai = async (type: any, prompt: any = 0, history_id: any = '') => {
    // , maxLength: number = 0
        let username = await getUserName();
        let password = await getUserPasswd();
        console.log(username, password);
        // console.log(history_id);
        if(username && password) {
            return axios({
                url: 'https://wiki.plantrocket.de/api/harries-api.php',
                method: 'get',
                params: {username: username, passwort: password, history_id: history_id ,history: items_prompt, type: type, prompt: prompt, token: 'a9=_xAH$8fX!+&l6WJ@p'},
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }).then(response => {
                // if(response.data !== '') {
                // console.log(response.data);
                // setItems(Parser(response.data));
                var  answer = response.data.split(":::::::::");
                console.log(answer[0]);
                console.log(answer[1]);
                console.log(answer[2]);
                setItems(Parser(answer[0]));
                setItemsPrompt(JSON.parse(answer[1]));
                setHistoryID(JSON.parse(answer[2]));
                // console.log(items_prompt);
                // console.log('history_id = ' + history_id);
                const neue_anfrage_button = document.getElementById('OpenaiSearchSubmit_2');
                if(neue_anfrage_button) {
                    neue_anfrage_button.style.display = 'block';
                }
            }).catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    if (error.response.status === 404) {

                    }
                }
            })
        }else {
            setItems('Fehlende Logindaten');
        }
    }
    const showHistoryApi = async (type: any) => {
        let username = await getUserName();
        let password = await getUserPasswd();
        console.log(username, password);
        if(username && password) {
            return axios({
                url: 'https://wiki.plantrocket.de/api/harries-api.php',
                method: 'get',
                params: {username: username, passwort: password, type: type},
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }).then(response => {
                console.log(response.data);
                setHistoryItems(Parser(response.data));

                let history_links = document.getElementsByClassName('history_link');
                for(let i=0;i<history_links.length;i++){
                    console.log(history_links[i]);
                    history_links[i].addEventListener("click", setHistoryItem);
                }
            }).catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    if (error.response.status === 404) {

                    }
                }
            })
        }else {
            setItems('Fehlende Logindaten');
        }
    }
    const setHistoryItem = async (input: any) => {
        if(input && input.target.dataset.history_id){
            // clearhistory().then(function (){
            //     console.log(input.target.dataset.history_id);
            //     setHistoryID(input.target.dataset.history_id);
            //     console.log('history_id ist ' + history_id);
                getAxiosDataopenai('openai', 0, input.target.dataset.history_id);
            // });
            setShowHistoryModal(false);
        }
    }
    async function copyPrompt(e:any, event:any){
        e.preventDefault();
        navigator.clipboard.writeText(event.content)
    }
    async function setbackground_func() {
        let value;
        if(isPlatform('desktop')) {
            value = background_desktop;
        }else  {
            value = background_mobile;
        }
        if(value) {setbackground(value);}
    }
    async function getUserName() {
        let {value} = await Storage.get({key: 'username' });
        if(value) {setUser(value);}
        return value;
    }
    async function getUserPasswd() {
        let {value} = await Storage.get({key: 'password' });
        if(value) {setPasswd(value);}
        return value;
    }
    const axiosopenai = async (form_data: any) => {
        const openai_loader = document.getElementById('OpenaiLoader');
        if(openai_loader) { openai_loader.style.display = 'block'; }
        // form_data.maxLengthrange.value,
            getAxiosDataopenai('openai', form_data.prompt.value, form_data.history_id.value).then(function(){
            if(openai_loader) { openai_loader.style.display = 'none'; }
            setPrompt('');
            // console.log('e', items_prompt[0]);
            // items_prompt.forEach((e:any) => {
            //     console.log(e.role);
            // })
        })
    }
    const sendopenaisearch = async (e: any) => {
        e.preventDefault();
        const form_data = e.target.elements;
        axiosopenai(form_data);
    }
    async function clearhistory() {
        console.log('history clear');
        setItemsPrompt('');
        setHistoryID('');
        const neue_anfrage_button = document.getElementById('OpenaiSearchSubmit_2');
        if(neue_anfrage_button) {
            neue_anfrage_button.style.display = 'none';
        }
    }
    async function showHistoryModalHandle() {
        setShowHistoryModal(true);
        showHistoryApi('show_history');
    }
    // async function maxLengthnumber_cahnge(e: any){
    //     console.log(e.target.value);
    //     setMaxLengthnumber(prev => ({ ...prev, ['value']: e.target.value }));
    // }
    const loadSaved = async () => {
        await getUserName();
        await getUserPasswd();
        await setbackground_func();
    }
    useEffect(() =>{
        if(settingsloaded === false) {
            const wiki = document.getElementById("Wiki_ContainerOpenai");
            (wiki as any).style.display = 'block';
            loadSaved();
            console.log(getPlatforms());
            console.log(isPlatform('desktop'));
            const openaiPrompt = document.getElementById('openaiPrompt');
            if(openaiPrompt){
                openaiPrompt.addEventListener("keydown", async function(e:any) {
                    if (e.key == 'Enter') {
                        const form_data = e.target.form;
                        axiosopenai(form_data);
                    } return false;
                });
            }
        }
    }, []);
  return (
    <IonPage>
      <IonContent fullscreen>
          <IonHeader collapse="condense">
              <div className="wiki_header"><img width="200px" src={logo} /></div>
          </IonHeader>
          <img className="wiki_background" src={background} />
          {/*<img className="wiki_background_icon" src={background_box_icon} />*/}
          <div id="OpenaiLoader">
              <img id="OpenaiLoaderImg" src={openai_loader} alt="Openai Loading Gif"/>
          </div>
          <div id="Wiki_ContainerOpenai">
              <IonList color="primary" className="col-12">
                  <div id="ShowHistory_outer">
                      <IonButton id="ShowHistory" onClick={() => showHistoryModalHandle()}>Ältere Suchanfragen</IonButton>
                      <IonButton id="OpenaiSearchSubmit_2" onClick={clearhistory}>Neues Gespräch starten</IonButton>
                  </div>
                  <IonModal id="ShowHistoryModal" isOpen={showHistoryModal} onDidDismiss={() => setShowHistoryModal(false)}>
                      <div id="ShowHistoryModalInner">
                          <h2>Ältere Suchanfragen:</h2>
                          <div id="ShowHistoryModalAusgabe">
                              {
                                  history_items
                              }
                          </div>
                      </div>
                      <IonButton onClick={() => setShowHistoryModal(false)}>Auflistung Schließen</IonButton>
                  </IonModal>
                  <div>
                      <h1 id="headline">Open AI</h1>
                      <p className="textheinweis">Nicht für SEO- oder Pressetexte verwenden<br/>
                      Nur für dienstliche Zwecke, da jede Eingabe Kosten generiert</p>
                  </div>
                  <div>
                      <form id="OpenaiSearch" onSubmit={e => sendopenaisearch(e!)}>
                          <input type="hidden"  name="history_id" value={history_id}/>
                          {/*<label id="maxLengthrangelabel">Maximale Wiedergabelänge (In Tokens):</label>*/}
                          {/*<IonItem>*/}
                          {/*    <input type="range" id="maxLengthrange" name="maxLengthrange" value={maxLengthnumber.value}*/}
                          {/*           onChange={e => maxLengthnumber_cahnge(e!)} min="1" max="4000" step="1"/>*/}
                          {/*    <input id="maxLengthnumber" name="maxLengthnumber" value={maxLengthnumber.value}*/}
                          {/*           onChange={e => maxLengthnumber_cahnge(e!)} type="number" min="1" max="4000"/>*/}
                          {/*</IonItem>*/}
                          <div color="primary" className="OpenAi_Ausgabe">
                              {
                                  items
                              }
                              {
                                  items_prompt && items_prompt[0] ?
                                      items_prompt.map((event:any, index:any, arr:any) => {
                                          if(event.role == 'user') {
                                              return (<div className="PromptQuestion" key={index} data-key={index} data-type={event.role}><h4>{event.content.replace(/<[^>]*>?/gm, '')}</h4></div>)
                                          }else {
                                              // let copy_button = arr.length - 1 === index ? "<IonButton onClick={(e) => copyPrompt(e, event)} id=\"CopyText\">In Zwischenablage kopieren</IonButton>" : '';
                                              return (<div className="PromptAnswer" key={index} data-key={index} data-type={event.role}>{Parser(event.content)}</div>)
                                          }
                                      })

                                      : ''
                              }
                          </div>
                          {
                              items_prompt && items_prompt[0] ?
                                  <IonButton id="CopyText" onClick={(e) => copyPrompt(e, items_prompt[items_prompt.length - 1])}>In Zwischenablage kopieren</IonButton>
                                  : ''
                          }
                          <div id="AIAnfrage_Outer">
                              <IonItem id="AIAnfrage">
                                  <IonInput id="openaiPrompt" value={prompt} onIonChange={e => setPrompt(e.detail.value!)} name="prompt" type="text" placeholder="AI Anfrage"/>
                              </IonItem>
                              <IonButton id="OpenaiSearchSubmit" type="submit">Anfrage starten</IonButton>
                          </div>
                      </form>
                  </div>
              </IonList>
          </div>
      </IonContent>
    </IonPage>
  );
};
export default Tab4;