import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, isPlatform} from '@ionic/react';
import { IonButton, IonList, IonItem, IonInput, IonItemDivider, IonImg, IonCheckbox, IonLabel } from '@ionic/react';
import logo from '../images/Wiki_Harries_Logo.png';
import background_box_icon from '../images/Harries_mobile_HG.png';
import background_desktop from '../webapp/images/Harries_mobile_HG-2.jpg';
import background_mobile from '../images/Harries_mobile_HG-2.jpg';
import Parser from 'html-react-parser';
import axios from 'axios';
import { useState, useEffect } from "react";
import ExploreContainer from '../components/ExploreContainer';
import './Tab1.css';
import {UserPhoto} from "../hooks/usePhotoGallery";
// import {importdata} from "../components/importdata";
import { Controller, useForm } from 'react-hook-form';
import { Storage } from "@capacitor/storage";
const Tab1: React.FC = () => {
    let settingsloaded = false;
    // const {items, setItems} = importdata();
    // const [text, setText] = useState<string>();
    const initialTags = [
        { id: 1, name: "Vertrieb", checked: 1},
        { id: 2, name: "Gestaltung", checked: 1}
    ]
    const [username, setUser] = useState<string>();
    const [password, setPasswd] = useState<string>();
    const [background, setbackground] = useState<string>();
    const [items, setItems] = useState<any>();
    const [newItems, setNewItems] = useState<any>();
    const [text, setText] = useState<string>();
    const [tags, setTags] = useState<any>(initialTags);
    // const [tags, setTags] = useState<any>();
    // const [set_tags, setSetTags] = useState<any>([]);
    const getAxiosData = async (type: any, input: any) => {
        let username = await getUserName();
        let password = await getUserPasswd();
        console.log(username, password);
        // const  all_tags = document.getElementsByClassName('WikiTags');
        // for (let i = 0; i < all_tags.length; i++) {
        //     console.log(all_tags[i], all_tags[i].children[0].children[0]);
        // }
        // console.log('TAGS: ',document.getElementsByClassName('WikiTags'));
        if(username && password) {
            return axios({
                // url: 'https://base.plantrocket.de/',
                url: 'https://wiki.plantrocket.de/api/harries-api.php',
                method: 'get',
                // auth: { username: 'entwurf', password: 'harries'},
                params: {username: username, passwort: password, type: type, query: input, tags: tags},
                // withCredentials: true,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                // headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': basicAuth }
            }).then(response => {
                // if(response.data !== '') {
                var  answer = response.data.split(":::::::::");
                console.log(answer[0]);
                setItems(Parser(answer[0]));
                let links = document.getElementsByClassName('inner_link');
                // console.log(links);
                for(let i=0;i<links.length;i++){
                    // console.log(links[i]);
                    links[i].addEventListener("click", getentry);
                }
                // console.log('TAGS: ',answer[1]);
                // setTags(JSON.parse(answer[1]));
                ////Download LInk
                // let dl_links = document.getElementsByClassName('download_link');
                // for(let i=0;i<links.length;i++){
                //     let dl_link = dl_links[i];
                //     console.log('dl_link', dl_link);
                //     const dl_url = dl_link.getAttribute("href");
                //     dl_link.addEventListener("click", async function(e) {
                //         e.preventDefault(); // Prevent browser's default download stuff...
                //         let dl_url = dl_link.getAttribute("href");
                //         if(dl_url) {
                //             let ref = window.open(dl_url, '_blank', 'location=no');
                //         }
                //     });
                // }
                // let link = document.getElementById("Link");
                // if(link) {(link as any).addEventListener("click", getentry);}
                // }
                // setText(text);
                // return response.data;
            }).catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    if (error.response.status === 404) {

                    }
                }
            })
        }else {
            setItems('Fehlende Logindaten');
        }
    }
    const getNewestEntries = async (type: any) => {
        let username = await getUserName();
        let password = await getUserPasswd();
        if(username && password) {
            return axios({
                url: 'https://wiki.plantrocket.de/api/harries-api.php',
                method: 'get',
                params: {username: username, passwort: password, type: type},
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }).then(response => {
                var  answer = response.data.split(":::::::::");
                console.log(answer[0]);
                setNewItems(Parser(answer[0]));
                // let links = document.getElementsByClassName('inner_link');
                // console.log('LINKS: ', links);
                // for(let i=0;i<links.length;i++){
                //     console.log('LINK'+i+': ' , links[i]);
                //     links[i].addEventListener("click", getentry);
                // }
            }).catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    if (error.response.status === 404) {

                    }
                }
            })
        }else {
            setItems('Fehlende Logindaten');
        }
    }
    async function setbackground_func() {
        let value;
        if(isPlatform('desktop')) {
            value = background_desktop;
        }else  {
            value = background_mobile;
        }
        if(value) {setbackground(value);}
    }
    const axiosx = async (type: any, input: any) => {
        // getUserName();
        // getUserPasswd();
        // console.log(username, password);
        // if(username && password) {
            await getAxiosData(type, input);
        // }else {
        //     setItems('Fehlende Logindaten');
        // }
    }

    const setdata = async (input: string) => {
        // const username = 'entwurf'
        // const password = 'harries'
        //
        // const token = btoa(username + ':' + password);
        // const basicAuth = 'Basic ' + token;
        // console.log(token);
        setText(input);
        axiosx('query', input);

        // axios.post('https://base.zplantrocket.de/', {
        //     withCredentials: true,
        //     headers: {
        //         "Accept": "application/json",
        //         "Content-Type": "application/json",
        //         'Authorization': + basicAuth
        //     }
        // },{
        //     auth: {
        //         username: "entwurf",
        //         password: "harries"
        //     }}
        // )
    }

    const changetags = async (input: any) => {
        // console.log('TESTXX: ', input, input.detail.checked, input.target.name);
        const this_index = tags.findIndex((tag:any) => tag.id == input.detail.value);
        if(input.detail.checked){
            tags[this_index].checked = 1;
        }else {
            tags[this_index].checked = 0;
        }
        console.log(this_index, tags);
        // console.log(set_tags);
        // axiosx('query', input);
    }

    const getentry = async (input: any) => {
        console.log('TEST', input);
        if(input && input.target.dataset.id){
            // console.log(input.target.dataset.id);
            await axiosx('entry', input.target.dataset.id);
        }
    }

    // const searchbar_outer = document.getElementById('Wiki_searchbar');
    // if(searchbar_outer) {
    //     const searchbar = searchbar_outer.firstElementChild;
    //     console.log(searchbar);
    //     if(searchbar) {
    //         searchbar.addEventListener("submit", function () {
    //             console.log('awd');
    //         });
    //     }
    // }
    // console.log(document.getElementsByClassName('wiki_container'));
    // console.log(document.getElementsByClassName('eintrag'));
    // console.log(document.getElementsByClassName('inner_link'));
    // (document.getElementsByClassName('eintrag')[0] as any).addEventListener("click", getentry);
    // const { control, handleSubmit } = useForm();
    // const setUserData = (data: any) => {
    //
    //     console.log('creating a new user account with: ', data, data.username, data.password);
    // }
    // const handleSubmit = async (input: string) => {
    //     console.log('aw');
    // }
    // async function savelogindata(login: any, login_einblenden: any, setlogin: any) {
    //     // console.log(setlogin);
    //     if (setlogin) {
    //         (setlogin as any).addEventListener("submit", function (e: any) {
    //             e.preventDefault();
    //             let password = e.target.elements.password.value;
    //             console.log('pass:', password);
    //             setPasswd(password);
    //             Storage.set({ key: 'password', value: password });
    //             let username = e.target.elements.username.value;
    //             console.log('user:', username);
    //             setUser(username);
    //             Storage.set({ key: 'username', value: username });
    //             if(login_einblenden && login) {
    //                 (login_einblenden as any).style.display = 'inline-block';
    //                 (login as any).style.display = 'none';
    //             }
    //             const searchbar_outer: any = document.getElementById('Wiki_searchbar');
    //             if(searchbar_outer) {
    //                 if(searchbar_outer.attributes && searchbar_outer.attributes['value']) {
    //                     // console.log(searchbar_outer.attributes['value'].value);
    //                     const input = searchbar_outer.attributes['value'].value;
    //                     axiosx('query', input);
    //                 } else {
    //                     axiosx('query', '');
    //                 }
    //             }
    //         });
    //     }
    // }
    async function savelogindata(e:any) {
        const login = document.getElementById("SetLogin");
        const wiki = document.getElementById("Wiki_Container");
        const wikinewEntry = document.getElementById("Wiki_NewEntries");
        const login_einblenden = document.getElementById("LoginEinblenden");
        e.preventDefault();
        let password = e.target.elements.password.value;
        console.log('pass:', password);
        setPasswd(password);
        Storage.set({ key: 'password', value: password });
        let username = e.target.elements.username.value;
        console.log('user:', username);
        setUser(username);
        Storage.set({ key: 'username', value: username });
        if(login_einblenden && login) {
            (login_einblenden as any).style.display = 'inline-block';
            (login as any).style.display = 'none';
            (wiki as any).style.display = 'block';
            (wikinewEntry as any).style.display = 'flex';
            await getNewestEntries('newentries');
        }
        const searchbar_outer: any = document.getElementById('Wiki_searchbar');
        if(searchbar_outer) {
            if(searchbar_outer.attributes && searchbar_outer.attributes['value']) {
                // console.log(searchbar_outer.attributes['value'].value);
                const input = searchbar_outer.attributes['value'].value;
                await axiosx('query', input);
            } else {
                await axiosx('query', '');
            }
        }
    }
    async function getUserName() {
        let {value} = await Storage.get({key: 'username' });
        if(value) {setUser(value);}
        // console.log(value);
        return value;
    }
    async function getUserPasswd() {
        let {value} = await Storage.get({key: 'password' });
        if(value) {setPasswd(value);}
        // console.log(value);
        return value;
    }
    const loadSaved = async () => {
        const login = document.getElementById("SetLogin");
        const wiki = document.getElementById("Wiki_Container");
        const wikinewEntry = document.getElementById("Wiki_NewEntries");
        const login_einblenden = document.getElementById("LoginEinblenden");
        // const setlogin = document.getElementById("SetLogin");
        await getUserName();
        await getUserPasswd();
        await setbackground_func();
        await getNewestEntries('newentries');
        // await savelogindata(login, login_einblenden, setlogin);
        if(login_einblenden && login){(login_einblenden as any).addEventListener('click', function () {
            login_einblenden.style.display = 'none';
            (login as any).style.display = 'block';
            (wiki as any).style.display = 'none';
            (wikinewEntry as any).style.display = 'none';
        })}
        const searchbar = document.getElementById('Wiki_searchbar');
        if(searchbar){
            const searchbar_clear = searchbar.querySelector('.input-clear-icon');
            if(searchbar_clear){
                searchbar_clear.addEventListener('click', function (){
                    axiosx('query', '');
                })
            }
        }
        setdata('');
        settingsloaded = true;
        // console.log(password);
    }

    useEffect(() =>{
        if(settingsloaded === false) {
            loadSaved();
        }
    }, []);

    if(username && password) {
        const login = document.getElementById("SetLogin");
        const wiki = document.getElementById("Wiki_Container");
        const wikinewEntry = document.getElementById("Wiki_NewEntries");
        const login_einblenden = document.getElementById("LoginEinblenden");
        if(login){(login as any).style.display = 'none';}
        if(wiki){(wiki as any).style.display = 'block';}
        if(wikinewEntry){(wikinewEntry as any).style.display = 'flex';}
        if(login_einblenden){(login_einblenden as any).style.display = 'inline-block';}
    }

  return (
    <IonPage>
      {/*<IonHeader>*/}
        {/*<IonToolbar>*/}
        {/*  <IonTitle>Harries Wiki</IonTitle>*/}
        {/*</IonToolbar>*/}
      {/*</IonHeader>*/}
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          {/*<IonToolbar>*/}
            <div className="wiki_header"><img width="200px" src={logo} /></div>
          {/*</IonToolbar>*/}
        </IonHeader>
        <img className="wiki_background" src={background} />
        {/*<img className="wiki_background_icon" src={background_box_icon} />*/}
          <form id="SetLogin" onSubmit={e => savelogindata(e!)}>
          {/*<form id="SetLogin" onSubmit={handleSubmit(e => setUserData(e))}>*/}
              {/*<IonItem>*/}
              {/*    <Controller*/}
              {/*    render={({*/}
              {/*                 field: { onChange, onBlur, value, name, ref },*/}
              {/*             }) => (<IonInput type="text" placeholder="Username"/>)}*/}
              {/*    name="username" control={control}*/}
              {/*    />*/}
              {/*</IonItem>*/}
              <IonItem>
                  <IonInput value={username} name="username" type="text" placeholder="Username"/>
              </IonItem>
              <IonItem>
                  <IonInput value={password} name="password" type="text" placeholder="Passwort"/>
              </IonItem>
              <IonButton id="LoginSubmit" type="submit">Login Daten bestätigen</IonButton>
          </form>
          <IonButton id="LoginEinblenden">Login Daten ändern</IonButton>
          <div id="Wiki_Container">
            {/*<ExploreContainer name="Tab 1 page" />*/}
            {/*  <IonItemDivider className="title_outer">Harries Wiki </IonItemDivider>*/}
            {/*<div className="col-12">*/}
            {/*    <img width="200px" src={logo} />*/}
            {/*</div>*/}
            {/*<div className="col-12">*/}
              <IonItem className="wiki_input">
                  <IonInput id="Wiki_searchbar" placeholder="Wiki durchsuchen" value={text} onIonChange={e => setdata(e.detail.value!)} clearInput={true}/>
              </IonItem>
              <div  id="WikiTags_outer">
                  {
                      tags && tags[1] ?
                          tags.map((event:any, index:any, arr:any) => {
                              // return (<IonItem className="WikiTags" key={index}>
                              //             <IonCheckbox checked id={event} name={event} value={index} onIonChange={e => changetags(e!)}></IonCheckbox>
                              //             <IonLabel>{event}</IonLabel>
                              //         </IonItem>);
                              return (<IonItem className="WikiTags" key={index}>
                                          <IonCheckbox checked={!!event.checked} id={event.name} name={event.name} value={event.id} onIonChange={e => changetags(e!)}></IonCheckbox>
                                          <IonLabel>{event.name}</IonLabel>
                                      </IonItem>);
                          }) : ''
                  }
              </div>
          <IonList color="primary" className="col-12 Wiki_Ausgabe">
              {
                  items
                  // items.map(item => {
                  //
                  //     return (
                  //         <IonItem>
                  //             {item['title']}
                  //             <IonButton href={item['url']} color="primary" slot="end">See article</IonButton>
                  //         </IonItem>
                  //     );
                  // })
              }
          </IonList>
          </div>
          <div id="Wiki_NewEntries">
              <div id="AlleEintraege_Header">Neueste Einträge:</div>
              {
                  newItems
              }
              <div id="AlleEintraege_outer"><IonButton id="AlleEintraegeButton" href="https://wiki.plantrocket.de/wiki-eintrage/" target="_blank">Alle Einträge als Liste</IonButton></div>
          </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
