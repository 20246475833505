import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonList,
    IonInput,
    isPlatform
} from '@ionic/react';
import logo from '../images/Wiki_Harries_Logo.png';
import background_box_icon from '../images/Harries_mobile_HG.png';
import background_desktop from '../webapp/images/Harries_mobile_HG-2.jpg';
import background_mobile from '../images/Harries_mobile_HG-2.jpg';
import ExploreContainer from '../components/ExploreContainer';
import Parser from 'html-react-parser';
import axios from 'axios';
import { useState, useEffect } from "react";
import './Tab3.css';
import { userLogin } from '../components/UserLogin';

const Tab3: React.FC = () => {
    let settingsloaded = false;
    const [background, setbackground] = useState<string>();
    const [items, setItems] = useState<any>();
    const getAxiosDatax = async (type: any) => {
            return axios({
                url: 'https://wiki.plantrocket.de/api/harries-api.php',
                method: 'get',
                params: {type: type},
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }).then(response => {
                // if(response.data !== '') {
                console.log(response.data);
                setItems(Parser(response.data));
            }).catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    if (error.response.status === 404) {

                    }
                }
            })
    }
    async function setbackground_func() {
        let value;
        if(isPlatform('desktop')) {
            value = background_desktop;
        }else  {
            value = background_mobile;
        }
        if(value) {setbackground(value);}
    }
    const axiosxx = async (type: any) => {
        await getAxiosDatax(type);
    }
    const loadSaved = async () => {
        await setbackground_func();
    }
    useEffect(() =>{
        if(settingsloaded === false) {
            loadSaved();
            const wiki = document.getElementById("Wiki_ContainerImp");
            (wiki as any).style.display = 'block';
            axiosxx('kontakt');
        }
        settingsloaded = true;
    }, []); // An empty dependency array means this effect will only run once, on component mount
  return (
    <IonPage>
      <IonContent fullscreen>
          <IonHeader collapse="condense">
              <div className="wiki_header"><img width="200px" src={logo} /></div>
          </IonHeader>
          <img className="wiki_background" src={background} />
          {/*<img className="wiki_background_icon" src={background_box_icon} />*/}
          <div id="Wiki_ContainerImp">
              <IonList color="primary" className="col-12 Wiki_Ausgabe">
                  <h2>Kontaktdaten</h2>
                  {
                      items
                  }
              </IonList>
          </div>
      </IonContent>
    </IonPage>
  );
};
export default Tab3;
